import { useEffect, useState } from "react";

// Models
import ICarouselDTO from "../Models/DTOs/ICarouselDTO";

// Api
import { GetTopRatedCarousel } from "../Api/Carousel";

function useFetchTopRated(countryCode: string, userId: string) {
    const
        [topRated, setTopRated] = useState<ICarouselDTO | null>(null),
        [topRatedLoading, setTopRatedLoading] = useState<boolean>(false),
        [topRatedError, setTopRatedError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        setTopRatedLoading(true);

        (async () => {
            try {
                const data = await GetTopRatedCarousel(countryCode, userId);
                if (!isCancelled) {
                    setTopRated(data);
                }
            } catch (err) {
                if (!isCancelled) {
                    setTopRatedError("Failed to fetch top rated carousel.");
                    console.error(err);
                }
            } finally {
                if (!isCancelled) {
                    setTopRatedLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, []);

    return { topRated, topRatedLoading, topRatedError };
}

export default useFetchTopRated;
