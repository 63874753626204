import React from "react";

// Assets
import IconFullScreen from "../../Assets/SVGs/Icons/FullScreen";
import IconFullScreenRevert from "../../Assets/SVGs/Icons/FullScreenRevert";

interface VideoFullscreenProps {
    containerRef: React.RefObject<HTMLDivElement>;
    isFullscreen: boolean;
    setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
    showControls: boolean;
}

const VideoFullscreen: React.FC<VideoFullscreenProps> = ({ containerRef, isFullscreen, setIsFullscreen, showControls }) => {
    const toggleFullscreen = () => {
        if (!containerRef.current) return;

        if (isFullscreen) {
            document.exitFullscreen()
                .then(() => setIsFullscreen(false))
                .catch((err) => console.error("Error exiting fullscreen:", err));
        } else {
            containerRef.current.requestFullscreen()
                .then(() => setIsFullscreen(true))
                .catch((err) => console.error("Error entering fullscreen:", err));
        }
    };

    return (
        <button
            className="video-controls__button video-controls__fullscreen"
            onClick={toggleFullscreen}
            aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
            { isFullscreen ? IconFullScreenRevert() : IconFullScreen() }
        </button>
    );
};

export default VideoFullscreen;
