import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { TvPlatform } from "./Models/Enums/TvPlatforms";
import { RoutePaths } from './Constants/RoutePaths';

// Style
import "./SCSS/App.scss";

// Screens
import FrontPage from "./Screens/LandingPages/FrontPage";
import LoginScreen from "./Screens/Account/Authentication/LoginScreen";
import NewsDetailsScreen, { Loader as newsDetailsLoader } from "./Screens/Watch/NewsDetailsScreen";
import SeriesDetailsScreen from "./Screens/Watch/SeriesDetailsScreen";
import WatchVideo from "./Screens/Watch/WatchVideo";
import AccountScreen from "./Screens/Account/AccountScreen";
import ArticlesScreen from "./Screens/Read/ArticlesScreen";
import ArticleCategoryScreen from "./Screens/Read/ArticleCategoryScreen";
import ArticleDetailsScreen from "./Screens/Read/ArticleDetailsScreen";
import SignUp from "./Screens/Account/SignUp";
import SignUpSuccess, { Loader as signUpSuccessLoader } from "./Screens/Account/SignUpSuccess";
import ChoosePlanScreen, { Loader as choosePlanLoader } from "./Screens/Account/Subscription/ChoosePlanScreen";
import ConfirmSubscriptionScreen, { Loader as confirmSubLoader } from "./Screens/Account/Subscription/ConfirmSubscriptionScreen";
import TrailerScreen from "./Screens/Watch/TrailerScreen";
import HelpCentreScreen from './Screens/Company/HelpCentreScreen';
import TermsOfUseScreen from "./Screens/Company/TermsOfUseScreen";
import PrivacyPolicyScreen from "./Screens/Company/PrivacyPolicyScreen";
import RenewScreen, { Loader as renewLoader } from "./Screens/Account/Subscription/RenewScreen";
import ErrorScreen from "./Screens/ErrorScreen";
import RootLayout from "./Screens/RootLayout";
import Renew3DSConfirmScreen from "./Screens/Account/Subscription/Renew3DSConfirmScreen";
import CareerScreen from "./Screens/Company/CareerScreen";
import TvAuthenticationScreen from "./Screens/Account/Authentication/TvAuthenticationScreen";
import ForgotPasswordScreen, { Loader as forgotLoader } from "./Screens/Account/Password/ForgotPasswordScreen";
import ResetPasswordScreen, { Loader as resetLoader } from "./Screens/Account/Password/ResetPasswordScreen";
import AffiliateScreen, { Loader as affiliateLoader } from "./Screens/AffiliateScreen";
import CategoryScreen from "./Screens/Watch/ContentListScreens/CategoryScreen";
import CategoryFilmsScreen from "./Screens/Watch/ContentListScreens/CategoryFilmsScreen";
import AuthScreenRedirect from "./Components/AuthScreenRedirect";

// Landing Pages
import LifetimeMembership from "./Screens/LandingPages/LifetimeMembership";

// Subscribed Screens
import Browse from "./Screens/Watch/Browse";
import FilmsScreen from "./Screens/Watch/ContentListScreens/Subscribed/FilmsScreen";
import SeriesScreen from "./Screens/Watch/ContentListScreens/SeriesScreen";
import CategoryDetailsScreen from "./Screens/Watch/ContentListScreens/Subscribed/CategoryDetailsScreen";

// Free Screens
import CategoryDetailsScreenFree from "./Screens/Watch/ContentListScreens/Free/CategoryDetailsScreenFree";
import FilmsScreenFree from "./Screens/Watch/ContentListScreens/Free/FilmsScreenFree";

// Enums
import { PopUpType } from "./Models/Enums/PopUpType";
import { BannerType } from "./Models/Enums/BannerType";

function App() {
    const router = createBrowserRouter([{
        path: RoutePaths.Root,
        id: "root",
        element: <RootLayout />,
        errorElement: <ErrorScreen />,
        children: [
            // Unauthenticated pages
            { index: true, element: <FrontPage /> },
            { path: "Login/:redirectTo?", element: <LoginScreen /> }, // Optional parameter, do not change to RoutePaths
            { path: RoutePaths.ForgotPassword, element: <ForgotPasswordScreen />, loader: forgotLoader },
            { path: RoutePaths.ResetPassword(":userId", ":code"), element: <ResetPasswordScreen />, loader: resetLoader },
            ...RoutePaths.SignUp.map((path, index) => ({
                path,
                element: <SignUp key={index} />
            })),
            { path: RoutePaths.SignUpSuccess, element: <SignUpSuccess />, loader: () => signUpSuccessLoader() },
            { path: RoutePaths.Affiliate(":link"), element: <AffiliateScreen />, loader: affiliateLoader },

            // Create your account
            { path: RoutePaths.CreateYourAccountGameChanger, element: <SignUp /> },
            { path: RoutePaths.CreateYourAccountTruthSeeker, element: <SignUp /> },

            // Marketing Landing Pages
            { path: RoutePaths.LifetimeMembership, element: <LifetimeMembership /> },

            // Authenticated pages
            { path: RoutePaths.Account, element: <AccountScreen /> },
            { path: RoutePaths.ChoosePlan, element: <ChoosePlanScreen />, loader: choosePlanLoader },
            { path: RoutePaths.ConfirmSubscription, element: <ConfirmSubscriptionScreen />, loader: confirmSubLoader },
            { path: RoutePaths.Renew, element: <RenewScreen />, loader: renewLoader },
            { path: RoutePaths.Browse, element: <Browse /> },
            { path: RoutePaths.Watch(":id"), element: <WatchVideo /> },
            { path: RoutePaths.Series, element: <SeriesScreen /> },
            { path: RoutePaths.Films, element: <AuthScreenRedirect FreeScreen={<FilmsScreenFree />} SubscribedScreen={<FilmsScreen />} /> },
            { path: RoutePaths.Category(':id'), element: <CategoryScreen /> },

            // Both authenticated and unauthenticated
            { path: RoutePaths.Renew3DSConfirm, element: <Renew3DSConfirmScreen /> },
            { path: RoutePaths.BrowseArticles, element: <ArticlesScreen /> },
            { path: RoutePaths.ArticleCategory(":id"), element: <ArticleCategoryScreen /> },
            { path: RoutePaths.ReadArticle(":id"), element: <ArticleDetailsScreen /> },
            { path: RoutePaths.ReadArticleOld(":id"), element: <ArticleDetailsScreen /> },
            { path: RoutePaths.SeriesTrailer(":id"), element: <TrailerScreen /> },
            { path: RoutePaths.WatchTrailer(":id"), element: <TrailerScreen /> },
            { path: RoutePaths.SeriesEpisodes(":id"), element: <SeriesDetailsScreen /> },
            { path: RoutePaths.News, element: <AuthScreenRedirect FreeScreen={<FrontPage />} SubscribedScreen={<CategoryDetailsScreen id={13} featuredImage={"https://ickesaimage.blob.core.windows.net/compressed/news-banner-3.jpg" } />} /> },
            { path: RoutePaths.WeeklyShows, element: <AuthScreenRedirect FreeScreen={<CategoryDetailsScreenFree popUpType={PopUpType.WeeklyShows} bannerType={BannerType.WeeklyShows} id={13} featuredImage={"https://ickesaimage.blob.core.windows.net/compressed/news-banner-3.jpg"} />} SubscribedScreen={<CategoryDetailsScreen popUpType={PopUpType.WeeklyShows} bannerType={BannerType.WeeklyShows} id={13} featuredImage={"https://ickesaimage.blob.core.windows.net/compressed/news-banner-3.jpg"} />} /> },
            { path: RoutePaths.NewsDetails(":id"), element: <NewsDetailsScreen />, loader: newsDetailsLoader },
            { path: RoutePaths.HelpCentre, element: <HelpCentreScreen /> },
            { path: RoutePaths.TermsOfUse, element: <TermsOfUseScreen /> },
            { path: RoutePaths.PrivacyPolicy, element: <PrivacyPolicyScreen /> },
            { path: RoutePaths.Careers, element: <CareerScreen /> },
            { path: RoutePaths.CategoryFilms(":id"), element: <CategoryFilmsScreen /> },

            // TV Auth
            { path: RoutePaths.GenericTvAuth, element: <TvAuthenticationScreen platform={TvPlatform.Generic} /> },
            { path: RoutePaths.Roku, element: <TvAuthenticationScreen platform={TvPlatform.Roku} /> },
            { path: RoutePaths.AndroidTV, element: <TvAuthenticationScreen platform={TvPlatform.AndroidTv} /> },
            { path: RoutePaths.SamsungTV, element: <TvAuthenticationScreen platform={TvPlatform.SamsungTv} /> },
            { path: RoutePaths.Firestick, element: <TvAuthenticationScreen platform={TvPlatform.FireStick} /> },

            // Additional Routes redirecting to specific pages
            { path: RoutePaths.Wtaf, element: <Navigate to={RoutePaths.SeriesEpisodes(107)} /> },
            { path: RoutePaths.DotConnector, element: <Navigate to={RoutePaths.SeriesEpisodes(121)} /> },
            { path: RoutePaths.TheDotConnector, element: <Navigate to={RoutePaths.SeriesEpisodes(121)} /> },
            { path: RoutePaths.RightNow, element: <Navigate to={RoutePaths.SeriesEpisodes(111)} /> },
            { path: RoutePaths.IsNowTheTime, element: <Navigate to={RoutePaths.SeriesEpisodes(128)} /> },
            { path: RoutePaths.DavidIcke, element: <AuthScreenRedirect FreeScreen={<CategoryDetailsScreenFree popUpType={PopUpType.DavidIcke} bannerType={BannerType.DavidIcke} id={17} featuredImage={"https://ickesaimage.blob.core.windows.net/compressed/di-banner-min-3.png"} />} SubscribedScreen={<CategoryDetailsScreen popUpType={PopUpType.DavidIcke} bannerType={BannerType.DavidIcke} id={17} featuredImage={"https://ickesaimage.blob.core.windows.net/compressed/di-banner-min-3.png"} />} /> },
            { path: RoutePaths.DavidIckeFilms, element: <CategoryFilmsScreen /> }
        ]
    }]);

    return (<RouterProvider router={router} />);
}

export default App;
