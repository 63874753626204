import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

// Hooks
import useFetchVideo from "../../Hooks/useFetchVideoById";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import VideoTitleDescription from "../../Components/VideoInfo/VideoTitleDescription";

function WatchVideo() {
    const
        authCtx = useContext(UserAuthenticationContext),

        // Parse the URL param into a number.
        { id } = useParams<string>(),
        videoId = id ? parseInt(id) : undefined,
        { video, videoLoading, videoError } = useFetchVideo(videoId, "GB"),
        [videoData, setVideoData] = useState<IVideoDTO>({} as IVideoDTO);

    useEffect(() => {
        if (video && video.Id) {
            console.log(video);
            setVideoData(video);
        }
    }, [video]);

    return (
        <div className="page--watch-video">
            <div className="page__wrapper">
                <VideoPlayer
                    video={videoData}
                    autoPlay={true}
                />

                <VideoTitleDescription
                    video={videoData}
                    isVideoLoading={videoLoading}
                />
            </div>
        </div>
    );
}

export default WatchVideo;
