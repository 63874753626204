import React, { useState, useRef, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// Components
import IconProfile from "../../Assets/SVGs/Icons/Profile";

// Models
import {IUserDetails} from "../../Models/Interfaces/IUserDetails";

// Constants
import {RoutePaths} from "../../Constants/RoutePaths";

// Assets
import IconGear from "../../Assets/SVGs/Icons/Gear";
import IconLifeRing from "../../Assets/SVGs/Icons/LifeRing";
import IconExit from "../../Assets/SVGs/Icons/Exit";

const AccountDropDown = (props: { userData: IUserDetails, logOut: () => void, tokenExists: boolean }) => {
    const
        navigate = useNavigate(),
        [isOpen, setIsOpen] = useState(false),
        dropdownRef = useRef<HTMLDivElement>(null),
        {t} = useTranslation(),

        handleLogOut = () => {
            props.logOut();

            // Redirect to front page
            setTimeout(() => {
                navigate("/");
            }, 500);
        },

        handleToggleDropDown = () => {
            //console.log('handleToggleDropDown');
            setIsOpen((prevState) => !prevState);
        },

        renderButtonInner = () => (
            <div className="account-dropdown__button" onClick={handleToggleDropDown}>
                <span>
                    {props.tokenExists ?
                        t('header-ui-account-button-label')
                        :
                        t('header-ui-sign-in-button-label')
                    }
                </span>

                {/* Profile icon and initial */}
                <div className="account-dropdown__icon">
                    {IconProfile()}
                    {props.userData.Person && props.userData.Person?.FirstName && (
                        <span>{props.userData.Person?.FirstName.substring(0, 1).toUpperCase()}</span>
                    )}
                </div>
            </div>
        ),

        renderDropDown = () => (
            <div onClick={handleToggleDropDown} className={`account-dropdown__dropdown ${isOpen ? 'is-open' : 'is-closed'}`}>
                <ul>
                    {props.userData.AspNetUserId && (
                        <>
                            <li><Link to={RoutePaths.Account}><>{IconGear()} Settings</></Link></li>
                            <li><Link to={RoutePaths.HelpCentre}><>{IconLifeRing()} Help Centre</></Link></li>
                        </>
                    )}

                    <li>
                        <button onClick={handleLogOut}><>{IconExit()} Log Out</></button>
                    </li>
                </ul>
            </div>
        ),

        handleLoginRedirect = () => {
            navigate(RoutePaths.Login);
        };

    // Close the dropdown when clicking outside the component
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            {props.tokenExists ? (
                <button className="account-dropdown">
                    {renderButtonInner()}
                    {renderDropDown()}
                </button>
            ) : (
                <div className="account-dropdown" onClick={() => handleLoginRedirect()}>
                    {renderButtonInner()}
                    {renderDropDown()}
                </div>
            )}
        </div>
    )
};

export default AccountDropDown;
