import React, { useRef, useEffect, useState, useCallback, useContext } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

// Components
import VideoProgressBar from "./VideoProgressBar";
import VideoPlayPause from "./VideoPlayPause";
import VideoLoadingOverlay from "./VideoLoadingOverlay";

// Context
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import VideoFullscreen from "./VideoFullScreen";

interface VideoPlayerProps {
    video: IVideoDTO;
    autoPlay: boolean;
}

// Wait time before hiding controls if no user input provided
const INACTIVITY_TIMEOUT = 3000; // ms

const VideoPlayer: React.FC<VideoPlayerProps> = ({ video, autoPlay }) => {
    const
        containerRef = useRef<HTMLDivElement | null>(null),
        videoRef = useRef<HTMLVideoElement | null>(null),
        playerRef = useRef<Player | null>(null),

        { hasUserInteracted } = useContext(GlobalInterfaceContext),

        // True when video.js has fully initialized
        [videoPlayerReady, setVideoPlayerReady] = useState(false),

        // State that shows / hides player controls (Play/pause has its own, slightly modified rules)
        [showControls, setShowControls] = useState(true),

        // Tracks if video is playing or not
        [isPlaying, setIsPlaying] = useState(false),

        // Tracks if video uses fullscreen or not
        [isFullscreen, setIsFullscreen] = useState(false),

        // tracks the timeout before hiding controls if user provides no further input
        inactivityTimerRef = useRef<number | null>(null),

        // Hides player controls
        hideControls = useCallback(() => {
            if (playerRef.current && !playerRef.current.paused()) {
                setShowControls(false);
            }
        }, []),

        togglePlay = useCallback(() => {
            const player = playerRef.current;
            if (!player) return;

            if (player.paused()) {
                player.play();
                setIsPlaying(true);
            } else {
                player.pause();
                setIsPlaying(false);
            }
        }, []),

        // Restarts the inactivity timer if user provides input over the player
        resetInactivityTimer = useCallback(() => {
            if (inactivityTimerRef.current) {
                window.clearTimeout(inactivityTimerRef.current);
            }

            inactivityTimerRef.current = window.setTimeout(() => {
                hideControls();
            }, INACTIVITY_TIMEOUT);
        }, [hideControls]),

        // Calls the timer reset function and sets the show/hide state
        showControlsAndResetTimer = useCallback(() => {
            //console.log('showControlsAndResetTimer');
            setShowControls(true);
            resetInactivityTimer();
        }, [resetInactivityTimer]),

        focusContainerRef = () => {
            if (containerRef.current) {
                containerRef.current.focus();
            }
        },

        // Pointer events to show controls + reset timer
        // (Removing onPointerLeave that forcibly hides controls)
        handlePointerMove = () => {
            showControlsAndResetTimer();
        },

        // User clicked inside video window
        handlePointerDown = () => {
            showControlsAndResetTimer();
            focusContainerRef();
        },

        // Handler for space-bar play / pause
        handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
            // Only respond to the space bar
            if (e.key === " ") {

                // Prevents the page from scrolling or any default behavior on space
                e.preventDefault();
                togglePlay();
                showControlsAndResetTimer();
            }
        };

    // Initialize the player
    useEffect(() => {
        console.log('VideoPlayer useEffect [video]');
        if (!videoRef.current) return;

        const shouldAutoplay = hasUserInteracted && autoPlay;

        if (!playerRef.current && video.Id) {
            const options = {
                autoplay: shouldAutoplay,
                controls: false,
                responsive: true,
                fluid: true,
                sources: [{ src: video.Link, type: "application/x-mpegURL" }],
            };

            playerRef.current = videojs(videoRef.current, options, () => {
                // setTimeout(() => {
                //
                // }, 3000);

                console.log("Video player ready!");
                setVideoPlayerReady(true);

                if (shouldAutoplay) {
                    setIsPlaying(true);
                    showControlsAndResetTimer();
                }

                // set default focus to container for space-bar play-pause support
                if (containerRef && containerRef.current) {
                    containerRef.current.focus();
                }
            });
        }

        // Cleanup player and state on unmount
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
                setVideoPlayerReady(false);
            }
        };
    }, [video]);

    // If the video is paused, show controls
    useEffect(() => {
        if (playerRef.current && playerRef.current.paused()) {
            showControlsAndResetTimer();
        }
    }, [playerRef, showControlsAndResetTimer]);

    // Listen for "ended" event to set isPlaying to false
    useEffect(() => {
        if (videoPlayerReady) {
            const player = playerRef.current;
            if (!player) return;

            const handleEnded = () => {
                setIsPlaying(false);
                showControlsAndResetTimer();
            };

            player.on("ended", handleEnded);

            return () => {
                player.off("ended", handleEnded);
            };
        }
    }, [videoPlayerReady, showControlsAndResetTimer, playerRef]);

    // Cleanup inactivity timer on unmount
    useEffect(() => {
        const handleFullscreenChange = () => {
            const isFull =
                document.fullscreenElement === containerRef.current ||
                (document as any).webkitFullscreenElement === containerRef.current ||
                (document as any).msFullscreenElement === containerRef.current;

            setIsFullscreen(isFull);
        };

        // Add event listeners for all relevant fullscreenchange events
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // Safari
        document.addEventListener("msfullscreenchange", handleFullscreenChange); // IE11

        return () => {
            if (inactivityTimerRef.current) {
                window.clearTimeout(inactivityTimerRef.current);
            }

            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, []);

    return (
        <div
            className="video-player"
            ref={containerRef}
            style={{ position: "relative" }}
            onPointerMove={() => handlePointerMove()}
            onPointerDown={() => handlePointerDown()}

            // Needed for space-bar play / pause support
            tabIndex={0}
            onKeyDown={handleKeyDown}
        >
            <div className="video-player__inner u-relative_hidden">
                <video
                    ref={videoRef}
                    className="video-js ickonic-video-player"
                />

                {playerRef.current && (
                    <>
                        <VideoPlayPause
                            isPlaying={isPlaying}
                            onTogglePlay={togglePlay}
                            showVideoPlayerOtherControls={showControls}
                        />

                        {videoPlayerReady && (
                            <div
                                className={`video-player__video-controls ${showControls ? 'is-not-hidden' : 'is-hidden'}`}>
                                <div className="video-controls__shelf">
                                    <VideoFullscreen
                                        containerRef={containerRef}
                                        isFullscreen={isFullscreen}
                                        setIsFullscreen={setIsFullscreen}
                                        showControls={showControls}
                                    />
                                </div>

                                <VideoProgressBar
                                    player={playerRef.current}
                                />
                            </div>
                        )}
                    </>
                )}

                <VideoLoadingOverlay
                    hideLoader={videoPlayerReady}
                />
            </div>
        </div>
    );
};

export default VideoPlayer;
