import { createContext, useState, ReactNode, useEffect } from "react";
import Cookies from "js-cookie";

// Define the shape of your context's value
interface GlobalInterfaceContextTypes {
    hasUserInteracted: boolean,
    isDarkMode: boolean;
    toggleDarkMode: any;
    darkModeDetection: any;
    mainMenuOpen: boolean;
    toggleMainMenuOpenState: any;
    mainMenuDesktopMode: boolean;
    toggleMainMenuDesktopMode: any;
    hasNotificationBar: boolean;
}

export const GlobalInterfaceContext = createContext<GlobalInterfaceContextTypes>({} as GlobalInterfaceContextTypes);

export const GlobalInterfaceContextProvider = (props: { children: ReactNode }) => {
    const
        [ hasUserInteracted, setHasUserInteracted ] = useState<boolean>(false),
        [ isDarkMode, setIsDarkMode] = useState<boolean>(true),
        [ isMainMenuDesktop, setIsMainMenuDesktop ] = useState<boolean>(false),
        [ isMainMenuOpen, setIsMenuOpen ] = useState<boolean>(false),
        [ hasNotificationBar, setHasNotificationBar ] = useState<boolean>(false);

    const toggleDarkMode = (isDarkMode: boolean) => {
        setIsDarkMode(isDarkMode);
    };

    const darkModeDetection = () => {

        // Creates a listener that fires if the device
        // changes its dark mode preference.
        const
            mediaQueryDarkMode = window.matchMedia('(prefers-color-scheme: dark)'),
            checkDeviceDarkModePreference = (event: MediaQueryListEvent) => {
                toggleDarkMode(event.matches);
            };

        // For browsers that support addEventListener on media query lists
        mediaQueryDarkMode.addEventListener('change', checkDeviceDarkModePreference);

        // Checks for the 'dark-mode' cookie. Cookie is set when
        // user makes a choice on the UI dropdown
        const darkModeCookie = Cookies.get('dark-mode');

        if (darkModeCookie === undefined) {
            if (mediaQueryDarkMode.matches) {

                //Cookies.set('dark-mode', 'true', { expires: 365 });
                toggleDarkMode(true);
                return;
            }

            //Cookies.set('dark-mode', 'false', { expires: 365 });
            toggleDarkMode(false);

        } else {

            // Defer to dark-mode cookie
            console.log(darkModeCookie);
        }
    }

    useEffect(() => {
        if (hasUserInteracted) return; // If already interacted, no need to add listeners

        const handleUserInteraction = () => {
            console.log('user has interacted with DOM');
            setHasUserInteracted(true);
            removeEventListeners();
        };

        const addEventListeners = () => {
            window.addEventListener('pointerdown', handleUserInteraction, { once: true, capture: true });
            window.addEventListener('touchstart', handleUserInteraction, { once: true, capture: true });
            window.addEventListener('keydown', handleUserInteraction, { once: true, capture: true });
        };

        const removeEventListeners = () => {
            window.removeEventListener('pointerdown', handleUserInteraction, { capture: true });
            window.removeEventListener('touchstart', handleUserInteraction, { capture: true });
            window.removeEventListener('keydown', handleUserInteraction, { capture: true });
        };

        addEventListeners();

        // Cleanup on unmount
        return () => {
            removeEventListeners();
        };
    }, [hasUserInteracted]);

    const value: GlobalInterfaceContextTypes = {
        hasUserInteracted,
        isDarkMode,
        toggleDarkMode,
        darkModeDetection,
        mainMenuOpen: isMainMenuOpen,
        toggleMainMenuOpenState: setIsMenuOpen,
        mainMenuDesktopMode: isMainMenuDesktop,
        toggleMainMenuDesktopMode: setIsMainMenuDesktop,
        hasNotificationBar
    };

    return <GlobalInterfaceContext.Provider value={value}>{ props.children }</GlobalInterfaceContext.Provider>;
};
