import React from "react";
import IconFullScreenRevert from "./FullScreenRevert";

const IconFullScreen = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M24 9h-2V4h-7V2h9zm-9 13v-2h7v-5h2v7zM0 15h2v5h7v2H0zM9 2v2H2v5H0V2z"> </path>
        </svg>
    )
};

export default IconFullScreen;
