import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ILoginDTO } from "../Models/DTOs/ILoginDTO";
import { IPageEntryDTO } from "../Models/DTOs/IPageEntryDTO";
import { IUserDetails } from "../Models/IUserDetails";
import qs from "qs";
import { IYourAccountDTO } from "../Models/DTOs/IYourAccountDTO";
import { IChangePasswordDTO } from "../Models/DTOs/IChangePasswordDTO";
import { IRegisterDTO } from "../Models/DTOs/IRegisterDTO.";
import config from "../Constants/Config";
import lscache from "lscache";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.account;

/**
 * Fetch the country code based on the user's IP address.
 * @returns Promise<string> - The country code (e.g., "US") or "Unknown" if it cannot be determined.
 */
async function getCountryCode(): Promise<string> {
    try {
        const response = await axios.get("https://ipapi.co/json/");
        return response.data.country_code || "Unknown"; // Return country code or "Unknown"
    } catch (error) {
        console.error("Error fetching country code:", error);
        return "Unknown"; // Fallback to "Unknown" on error
    }
}

export async function RegisterAPI(Dto: IRegisterDTO, abortController: AbortController) {
    const responce = await axios
        .post(BACKEND_URL + "RegisterAccount/", Dto, {
            signal: abortController.signal,
        })
        .then((responce) => {
            if (responce == null || responce.data == null || responce.data.length < 0) {
                console.log("Register error");
                return {
                    response: { status: 400, data: { message: "Bad Request" } },
                } as AxiosError;
            }

            return responce.data as string;
        })
        .catch((error: AxiosError) => {
            console.log("Register Error");
            return error;
        });

    return responce;
}

export async function LoginAPI(username: string, password: string, abortController: AbortController) {

    // Token has its own unique url that doesn't use the "account" config value
    let url = process.env.REACT_APP_API_URL?.replace('/api','/token');

    if (username == null || username.length <= 0 || password == null || password.length <= 0) {
        return null;
    }

    const data = {
        grant_type: "password",
        username: username,
        password: password,
    };

    const options: AxiosRequestConfig = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
        data: qs.stringify(data),
        url,
        signal: abortController.signal,
    };

    try {
        const response = await axios(options);
        return response.data as ILoginDTO;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log("Login Error");
            return error;
        }
        throw error;
    }
}

export async function GetUserDetails(access_token: string, abortController: AbortController) {
    const detailsResponce = await axios
        .get(BACKEND_URL + "GetUserDetails", {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then(async (result) => {
            if (result == null) {
                return null;
            }

            let userDetails = result.data as IUserDetails;
            userDetails.Access_Token = access_token;

            // Guard against blank AspNetUserId
            const userId = userDetails.AspNetUserId && userDetails.AspNetUserId.trim() !== ""
                ? userDetails.AspNetUserId
                : "Login failure";

            // Log page entry after retrieving user details
            const pageEntryDto: IPageEntryDTO = {
                UserEmail: userDetails.Email,
                PageCalled: "GetUserDetails",
                ProcessUsed: "Retrieve User Details",
                BrowserUsed: navigator.userAgent,
                DeviceUsed: /Mobi|Android/i.test(navigator.userAgent) ? "Mobile" : "Desktop",
                UserId: userId, // Populate with AspNetUserId
            };

            await LogPageEntry(pageEntryDto, access_token, abortController).catch((error) => {
                console.error("LogPageEntry failed:", error);
            });

            return userDetails;
        })
        .catch((error: AxiosError) => {
            console.log("GetUserDetails Error:", error);

            console.log(error);

            // Check if we have a 401 error
            if (error?.response?.status === 401) {
                console.log('detected 401 error, redirecting...');

                // Remove auth token from lscache
                lscache.remove("authToken");

                setTimeout(() => {
                    window.location.href = "/login";
                }, 500);
            }

            return error;
        });

    return detailsResponce;
}

export async function GetYourAccountDTO(
    userId: string,
    access_token: string,
    abortController: AbortController
) {
    const responce = await axios
        .get(BACKEND_URL + "GetYourAccountDetails/" + userId, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then((responce) => {
            console.log(responce);
            return responce.data as IYourAccountDTO;
        })
        .catch((error: AxiosError) => {
            console.log("GetYourAccountDetails Error");
            return error;
        });

    return responce;
}

export async function ChangePassword(
    data: IChangePasswordDTO,
    access_token: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "ChangePassword", data, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then((result) => {
            if (result == null) {
                return false;
            }
            return true;
        })
        .catch((error: AxiosError) => {
            console.log("ChangePassword Error");
            return error;
        });

    return responce;
}

export async function ChangeEmail(
    newEmail: string,
    access_token: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "ChangeEmail", null, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
            params: {
                email: newEmail,
            },
        } as AxiosRequestConfig)
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("ChangeEmail Error");
            return error;
        });

    return responce;
}

export async function ForgotPassword(email: string) {
    const encodedEmail = encodeURIComponent(email);

    const responce = await axios
        .post(BACKEND_URL + "ForgotPassword", {
            email: encodedEmail,
            url: window.location.origin
        } as AxiosRequestConfig)
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("ForgotPassword Error");
            return error;
        });

    return responce;
}

export async function ResetPassword(
    email: string,
    code: string,
    newPassword: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "ResetPassword", { email: email, code: code, password: newPassword }, {
            signal: abortController.signal,
        } as AxiosRequestConfig)
        .then((result) => {
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("ResetPassword Error");
            return error;
        });

    return responce;
}

export async function UpdateCommercialEmails(
    allow: boolean,
    access_token: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "UpdateCommercialEmails/" + allow, null, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then((result) => {
            if (result == null) {
                return false;
            }
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("UpdateCommercialEmails Error");
            return error;
        });

    return responce;
}

export async function UpdateDailyNewsLetter(
    allow: boolean,
    access_token: string,
    abortController: AbortController
) {
    const responce = await axios
        .post(BACKEND_URL + "UpdateDailyNewsletter/" + allow, null, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then((result) => {
            if (result == null) {
                return false;
            }
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("Update Daily Newsletter Error");
            return error;
        });

    return responce;
}

export async function DisconnectFromAllDevices(
    access_token: string,
    abortController: AbortController) {
    const responce = await axios
        .post(BACKEND_URL + "DisconnectFromAllDevices/", null, {
            signal: abortController.signal,
            headers: {
                Authorization: "Bearer " + access_token,
            },
        })
        .then((result) => {
            if (result == null) {
                return false;
            }
            return result.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("Disconnect From All Devices Error");
            return error;
        });

    return responce;
}

export async function LogPageEntry(
    pageEntryDto: IPageEntryDTO,
    access_token: string,
    abortController: AbortController
) {
    try {
        // Fetch the country code dynamically
        const countryCode = await getCountryCode();

        // Add the country code to the pageEntryDto object
        const enhancedPageEntryDto = {
            ...pageEntryDto,
            Country: countryCode,
        };

        // Log the page entry with the enhanced data
        const response = await axios.post(
            BACKEND_URL + "logPageEntry",
            enhancedPageEntryDto,
            {
                signal: abortController.signal,
                headers: {
                    Authorization: "Bearer " + access_token,
                },
            }
        );

        return response?.status === 202; // Return true if status is 202
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log("LogPageEntry Error:", error);
        }
        return false; // Return false on error
    }
}
