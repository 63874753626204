// BrowserScreen.js
import { useContext } from "react";
import UserOnboarding from "../../Components/GlobalUI/UserOnboarding";

// Context
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { GlobalInterfaceContext } from "../../Context/GlobalInterfaceContext";

// Components
import SliderFeaturedContent from "../../Components/Layouts/Sections/SliderFeaturedContent";
import CarouselPortrait from "../../Components/Layouts/Carousels/CarouselPortrait";
import CarouselLandscape from "../../Components/Layouts/Carousels/CarouselLandscape";
import CarouselItemPresenter from "../../Components/Layouts/Carousels/CarouselItemPresenter";

// Hooks
import useFetchPresenters from "../../Hooks/useFetchPresenters";
import useFetchJustReleased from "../../Hooks/useFetchJustReleased";
import useFetchFilms from "../../Hooks/useFetchFilms";
import useFetchTopRated from "../../Hooks/useFetchTopRated";
import useFetchCategoryContent from "../../Hooks/useFetchCategoryContent";
import useFetchEpisodesByYear from "../../Hooks/useFetchEpisodesByYear";
import useFetchCustomCarousels from "../../Hooks/useFetchCustomCarousels";

// Carousel definitions
import {
    CarouselKey,
    CarouselDefinitions,
} from "../../Models/Interfaces/ICarouselDefinition";

function Browse() {

    // 1) CONTEXTS
    const globalInterfaceContext = useContext(GlobalInterfaceContext);
    const authCtx = useContext(UserAuthenticationContext);

    // 2) USER INFO
    const userId = authCtx.userData.AspNetUserId ?? "1c624ef2-27a9-496f-b518-6f6e612078b6";
    const countryCode = !authCtx.userData.CountryCode || authCtx.userData.CountryCode === ""
        ? "GB"
        : authCtx.userData.CountryCode;

    // 3) HOOKS (DATA)
    const { presenters } = useFetchPresenters(userId);
    const { justReleased } = useFetchJustReleased(countryCode, userId);
    const { films, filmsLoading } = useFetchFilms(countryCode, userId, 30, 0, false);
    const { topRated } = useFetchTopRated(countryCode, userId);
    const { customCarousels } = useFetchCustomCarousels(countryCode, userId);

    const {
        episodes: trendingContent,
        episodesLoading: trendingLoading,
    } = useFetchEpisodesByYear(185, countryCode, userId, 30, 0, 0, false, "d");

    const {
        categoryContent: consciousnessContent,
        contentLoading: consciousnessLoading,
    } = useFetchCategoryContent(5, countryCode, userId, 30, 0, false);

    const {
        categoryContent: healthContent,
        contentLoading: healthLoading,
    } = useFetchCategoryContent(21, countryCode, userId, 30, 0, false);

    const {
        categoryContent: seriesContent,
        contentLoading: seriesLoading,
    } = useFetchCategoryContent(18, countryCode, userId, 30, 0, false);

    const {
        categoryContent: davidIckeContent,
        contentLoading: davidIckeLoading,
    } = useFetchCategoryContent(17, countryCode, userId, 30, 0, false);

    const {
        categoryContent: docsContent,
        contentLoading: docsLoading,
    } = useFetchCategoryContent(3, countryCode, userId, 30, 0, false);

    const {
        categoryContent: occultContent,
        contentLoading: occultLoading,
    } = useFetchCategoryContent(29, countryCode, userId, 30, 0, false);

    // 4) USER PREFERENCES
    const userPrefString: string | undefined = authCtx.userData?.Person?.PrefCategories;

    // Type guard so TS knows which strings are valid keys
    function isCarouselKey(k: string): k is CarouselKey {
        return k in CarouselDefinitions;
    }

    // Example: userPrefString => "OriginalFilms,OriginalSeries,HealthAndWellbeing"
    // => userPrefs = ["OriginalFilms","OriginalSeries","HealthAndWellbeing"]
    const userPrefs: CarouselKey[] = userPrefString
        ? userPrefString.split(",").filter(isCarouselKey)
        : [];

    // 5) FORCED CAROUSELS (STATIC): We'll define them in the
    //    exact order we want them to appear at the top.
    const forcedCarousels: CarouselKey[] = [
        "WeeklyShows",
        "JustReleased",
        "Trending",
        // "CustomCarousels"
    ];
    // We want these 4 to be at the top, in that order.

    // 6) DETERMINE "LEFTOVER" CAROUSELS
    //   => All carousels in your definitions that are NOT in forcedCarousels
    //      and NOT in the user's preferences
    const allDefinitionKeys = Object.keys(CarouselDefinitions) as CarouselKey[];

    // If you want to ensure you don't double-include forced items that happen
    // to appear in the user's prefs, you can remove forcedCarousels from userPrefs
    // or just let them appear later—whichever is correct for your scenario.
    // Typically, we'd skip them from user prefs, because they're forced:
    const userPrefsWithoutForced = userPrefs.filter(
        (k) => !forcedCarousels.includes(k)
    );

    // Now leftover = everything that’s neither forced nor in user prefs
    const leftoverCarousels = allDefinitionKeys.filter(
        (k) => !forcedCarousels.includes(k) && !userPrefsWithoutForced.includes(k)
    );

    // 7) BUILD THE FINAL ORDER
    //    forcedCarousels => userPrefs (minus forced) => leftover
    const finalLayout: CarouselKey[] = [
        ...forcedCarousels,
        ...userPrefsWithoutForced,
        ...leftoverCarousels,
    ];

    // 8) HELPER: get data for each carousel key
    function getCarouselDataByKey(key: CarouselKey) {
        switch (key) {
            case "WeeklyShows":
                return { items: presenters?.Content, loading: false };
            case "JustReleased":
                return { items: justReleased?.Content, loading: false };
            case "Trending":
                return { items: trendingContent, loading: trendingLoading };
            // case "CustomCarousels":
            //     return { items: customCarousels, loading: false };
            case "TopRated":
                return { items: topRated?.Content, loading: false };
            case "DavidIcke":
                return { items: davidIckeContent, loading: davidIckeLoading };
            case "OriginalFilms":
                return { items: films, loading: filmsLoading };
            case "OriginalSeries":
                return { items: seriesContent, loading: seriesLoading };
            case "Consciousness":
                return { items: consciousnessContent, loading: consciousnessLoading };
            case "HealthAndWellbeing":
                return { items: healthContent, loading: healthLoading };
            case "Documentaries":
                return { items: docsContent, loading: docsLoading };
            case "OccultAndHiddenKnowledge":
                return { items: occultContent, loading: occultLoading };
            default:
                return { items: [], loading: false };
        }
    }

    // 9) MAP FINAL LAYOUT TO DEFINITIONS & RENDER
    const finalCarousels = finalLayout.map((key) => ({
        key,
        def: CarouselDefinitions[key],
    }));

    // console.log(customCarousels);

    return (
        <>
            <UserOnboarding isDarkMode={globalInterfaceContext.isDarkMode} />

            <div className="page--browse">

                {/* If you want the SliderFeaturedContent always at top */}
                <SliderFeaturedContent isDarkMode={globalInterfaceContext.isDarkMode} />

                {finalCarousels.map(({ key, def }) => {
                    const { items, loading } = getCarouselDataByKey(key);

                    if (def.componentType === "portrait") {
                        // Example: "WeeklyShows" might have a custom item
                        return (
                            <div className="page-browse__carousel-container" key={key}>
                                <CarouselPortrait
                                    heading={def.heading}
                                    isDarkMode={globalInterfaceContext.isDarkMode}
                                    carouselItems={loading ? undefined : items}
                                    buttonText={def.buttonText}
                                    buttonLink={def.buttonLink}
                                    renderItem={
                                        key === "WeeklyShows"
                                            ? (item, isItemLoading) => (
                                                <CarouselItemPresenter
                                                    item={item}
                                                    isLoading={isItemLoading}
                                                    userId={userId}
                                                    countryCode={countryCode}
                                                />
                                            )
                                            : undefined
                                    }
                                />
                            </div>
                        );
                    } else {
                        // "landscape" carousel
                        return (
                            <div className="page-browse__carousel-container" key={key}>
                                <CarouselLandscape
                                    heading={def.heading}
                                    accentColor={def.accentColor}
                                    isDarkMode={globalInterfaceContext.isDarkMode}
                                    carouselItems={loading ? undefined : items}
                                    buttonText={def.buttonText}
                                    buttonLink={def.buttonLink}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
}

export default Browse;
