import { useContext, useEffect, useState, ReactNode } from "react";
import {Outlet, useLocation} from "react-router-dom";
import lscache from "lscache";

// Components
import AcceptCookies from "../Components/GlobalUI/AcceptCookies";
import Footer from "../Components/UI/Navigation/Footer";
import Toast from "../Components/UI/Toasts/Toast";
import ScrollToTop from "../Components/UI/Navigation/ScrollToTop";
import MarketingScripts from "../Components/Headers/MarketingScripts";
import Header from "../Components/GlobalUI/Header";
import NotificationBar from "../Components/GlobalUI/NotificationBar";

// Helpers
import { ShowWelcomeToast } from "../Helpers/Utility";

// Context
import {UserAuthenticationContext} from "../Context/UserAuthenticationContext";
import {GlobalInterfaceContext} from "../Context/GlobalInterfaceContext";

// Constant
import {URLsWithoutUI, URLsWithNotifyBar} from "../Constants/RoutePaths";

const RootLayout = (props: {children?: ReactNode}) => {
    const
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        authCtx = useContext(UserAuthenticationContext),
        location = useLocation(),
        [isLoaded, setIsLoaded] = useState<boolean>(false),
        currentLocation = useLocation(),
        shouldDisplayGlobalUI = !URLsWithoutUI.some((path) => path.test(currentLocation.pathname)),
        shouldDisplayNotifyBar = URLsWithNotifyBar.some((path) => path.test(currentLocation.pathname));

    useEffect(() => {
        if (isLoaded) {
            ShowWelcomeToast(authCtx?.userData.Person?.FirstName, location);
            return;
        }

        setTimeout(() => {
            setIsLoaded(true);
        }, 500);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx?.userData]);

    useEffect(() => {
        console.log('RootLayout Did Mount');
        if (authCtx && authCtx.doesAuthTokenExist()) {
            const token = lscache.get('authToken');
            if (token && token !== "") {
                authCtx.fetchUserData(token);
            }
        }
    }, []);

    return (
        <main role="main" className={shouldDisplayNotifyBar && globalInterfaceContext.hasNotificationBar ? 'has-notification-bar' : 'notification-bar-hidden'}>

            <MarketingScripts/>

            { /** Tracking Consent Banner **/}
            <AcceptCookies/>

            { /** Alert and top notification bar **/ }
            { shouldDisplayGlobalUI && shouldDisplayNotifyBar && globalInterfaceContext.hasNotificationBar && <NotificationBar isDarkMode={globalInterfaceContext.isDarkMode} /> }

            { /** Main Header w/ Primary Nav & Profile / Search / Alerts **/ }
            { shouldDisplayGlobalUI && <Header shouldDisplayNotifyBar={shouldDisplayNotifyBar} /> }

            <div
                className={`container
                ${shouldDisplayGlobalUI ? 'has-header' : 'has-no-header'}
                ${globalInterfaceContext.isDarkMode ? 'is-dark-mode' : 'is-light-mode'}
                ${globalInterfaceContext.mainMenuDesktopMode ? "menu-is-desktop" : "menu-is-mobile"}
                ${authCtx.userData.AspNetUserId ? 'user-is-authenticated' : 'user-not-authenticated'}
                `}>

                { /**
                 Currently only used to compose the <Error> component...
                 React-Router expects a single comp for the errorElement prop,
                 but I still want to include the rest of the UI without duplicating
                 code in that file, so I pass <Error /> as a child to Root.
                 **/}
                {props?.children}

                { /** React Router Body **/}
                <Outlet/>
            </div>

            { shouldDisplayGlobalUI && (
                <>
                    <Footer/>

                    <Toast/>

                    <ScrollToTop/>
                </>
            ) }
        </main>
    );
}

export default RootLayout;
