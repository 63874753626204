import { useEffect, useState } from "react";

// Api
import { GetLatestVideo } from "../Api/Video";

// Models
import IVideoDTO from "../Models/DTOs/IVideoDTO";

function useFetchSeriesLatestVideo(
    seriesId: number | null,
    country: string
) {
    const
        [latestVideo, setLatestVideo] = useState<IVideoDTO>({} as IVideoDTO),
        [latestVideoLoading, setLatestVideoLoading] = useState(false),
        [latestVideoError, setLatestVideoError] = useState("");

    useEffect(() => {
        let isCancelled = false;
        const abortController = new AbortController();

        if (seriesId !== null) {
            setLatestVideoLoading(true);

            (async () => {
                try {
                    // Updated GetLatestVideo to accept an AbortController if needed.
                    // Adjust if your actual signature is different.
                    const data = await GetLatestVideo(seriesId, country, abortController);
                    if (!isCancelled && data) {
                        // For infinite scrolling/pagination, append new data:
                        setLatestVideo(data);
                        // If you prefer to overwrite on each fetch, use:
                        // setLatestVideo(data);
                    }
                } catch (error) {
                    if (!isCancelled) {
                        console.error("Failed to fetch series:", error);
                        setLatestVideoError("Failed to fetch series.");
                    }
                } finally {
                    if (!isCancelled) {
                        setLatestVideoLoading(false);
                    }
                }
            })();
        }

        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [seriesId]);

    return { latestVideo, latestVideoLoading, latestVideoError };
}

export default useFetchSeriesLatestVideo;
