// src/components/VideoPlayPause.tsx

import React from 'react';
import { IckonicLogoIcon } from "../../Assets/SVGs/Logos";

interface VideoPlayPauseProps {
    isPlaying: boolean;                  // Passed from parent
    onTogglePlay: () => void;            // Passed from parent
    showVideoPlayerOtherControls: boolean;
}

const VideoPlayPause: React.FC<VideoPlayPauseProps> = ({
    isPlaying,
    onTogglePlay,
    showVideoPlayerOtherControls
}) => {
    return (
        <div
            className={`video-player__play-pause u-full_cover_absolute ${
                showVideoPlayerOtherControls || !isPlaying ? 'is-not-hidden' : 'is-hidden'
            }`}
            onClick={(e) => {
                e.stopPropagation(); // Prevents parent onPointerDown from firing
                onTogglePlay();
            }}
            role="button"                      // Accessibility: define role
            aria-label={isPlaying ? "Pause video" : "Play video"} // Accessibility: define label
            tabIndex={0}                       // Make it focusable for keyboard users
        >
            <div className="play-pause__inner">
                {isPlaying ? (
                    /* Pause Icon */
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="white"
                    >
                        <rect x="5" y="4" width="4" height="16" />
                        <rect x="15" y="4" width="4" height="16" />
                    </svg>
                ) : (
                    /* Play Icon */
                    IckonicLogoIcon()
                )}
            </div>
        </div>
    );
};

export default VideoPlayPause;
