import React from "react";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

interface VideoInfoProps {
    video: IVideoDTO;
    isVideoLoading: boolean;
}

const VideoTitleDescription: React.FC<VideoInfoProps> = ({ video, isVideoLoading }) => {
    return (
        <div className="watch-video__title-description">
            <h2>{video.Title}</h2>
            <p>{video.Description}</p>
        </div>
    );
};

export default VideoTitleDescription;
